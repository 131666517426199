.br-top {
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.br-bottom {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.br-left {
    /* border-left: 1px solid rgba(0, 0, 0, 0.1); */
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.br-right {
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.br-customColor{
    border-color: #326BD5 !important;
    cursor: pointer;
}
.txt-custom{
    color: #326BD5 !important;
}
.text-tittle-card{
    font-size: 10pt;    
}
.text-subtittle-card{
    font-size: 8pt;    
}
.text-subtittle-card-1{
    font-size: 7pt;    
}
.background_card_1{
    background-image: url('/public/svg/highligh-dashboard.svg') !important;
    background-size: contain !important;
    background-position-y: bottom !important;
    background-repeat: no-repeat;
    background-color: #F2BA22 !important;
}
.background_card_2{
    background-image: url('/public/svg/highligh-dashboard.svg') !important;
    background-size: contain !important;
    background-position-y: bottom !important;
    background-repeat: no-repeat;
    background-color: #326BD5 !important;
}
.background_card_3{
    background-image: url('/public/svg/highligh-dashboard.svg') !important;
    background-size: contain !important;
    background-position-y: bottom !important;
    background-repeat: no-repeat;
    background-color: #46CCB1 !important;
}
.header_pmd_display{
    height: 182px;
    background-image: url('/public/svg/pmdashboardimg.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
  .accordion-icon::before {
    color: #333;
    content: "\f100";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.acordion-body .dx-accordion-item-body{
    background-color: #F0F0F0;
}
.dropdown-test > button {
    color: #1BC5BD !important;
    background-color: transparent !important;
    border-color: #1BC5BD !important;
}
.dropdown-test > button:hover {
    color: white !important;
    background-color: #1BC5BD !important;
    border-color: #1BC5BD !important;
}
.dropdown-test > button:hover {
    color: white !important;
    background-color: #1BC5BD !important;
    border-color: #1BC5BD !important;
}
.dropdown-test > button:hover > .svg-icon-success svg g [fill]{
    fill: white !important;
}
.dropdown-test .btn.btn-primary.dropdown-toggle:after {
    display: none;
}
.custom-ac .accordion .card .card-header {
    cursor: pointer;
    margin: 0;
    border-bottom: 0;
    padding: 0;
    background-color: white;
}
.status-bg-open{
    background-color: rgba(70, 204, 177,0.2);
    color: #46CCB1;
}
.status-bg-close{
    background-color: rgba(242, 186, 34, 0.2);
    color: #F2BA22;
}
.myproject{
    background-color: rgba(255, 255, 255, 0.5);
}
.text-standard{
    font-size: 12px;
}