.parent-dok {
  font-family: 'Times New Roman', Times, serif !important;
}

.create-by-style {
  font-family: 'Times New Roman', Times, serif;
  display: block;
  text-align: right;
  font-weight: bold;
  font-style: italic !important;
  color: whitesmoke; 
  margin-right: '35px';
}

table.tb-gray thead,
table.tb-gray tfoot {
  background: #cccccc;
  font-weight: bold;
}

table.tb-gray thead>tr>th,
table.tb-gray tfoot tr td {
  padding: 10px;
}

.toolbar-pdf {
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0px;
  left: 0;
  min-height: 55px;
  box-shadow: 0px -8px 12px 11px #e0e0e0;
  padding: 10px;
  z-index: 99;
}

.page-root {
  background: #ffffff;
  position: relative;
  width: 1366px;
  min-height: 965px;
  margin-bottom: 15px;
  flex: none;
  padding-top: 65px;
  padding-bottom: 100px;
  page-break-before: always;
  margin: auto;
  padding-left: 35px;
  padding-right: 35px;
}

.container-pdf {
  width: 100%;
  /* min-height: 100%; */
  /* padding: 0 330px; */
  margin-top: 100px;
}

/* .container-pdf > .page-root {
  margin: auto;
} */

span.bulan-tahun {
  background: #c070cc;
  color: white;
  padding: 8px;
  text-transform: uppercase;
}

span.tipe-proyek {
  background: #006cb8;
  color: white;
  padding: 8px;
  text-transform: uppercase;
}

.cover {
  background: url("bg-cover.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-size: cover;
  background-position: center;
}

.blue_2 {
  background: #F0F8FF;
}

.blue_1 {
  background: #c2eeff;
}

.violet_1 {
  background: #d4c2ff;
}

.violet_2 {
  background: #f2ecff;
}

.green_1 {
  background: #a2ffb5;
}

.green_2 {
  background: #dfffe5;
}

.bg-blue-sky {
  background-color: #EAF0FF !important;
  color: #575962 !important;
}

.cover>.body-cover {
  width: 580px;
  float: right;
  min-height: 100px;
  padding: 10px;
}

.type_p {
  background: #006cb8;
  width: 500px;
  padding: 6px;
  padding-left: 12px;
  color: white;
  font-weight: bolder;
}

.bg {
  background: url("./bg-page.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-size: cover;
  background-position: center;
}

.page-header {
  width: 1366px;
  position: relative;
  height: 80px;
  top: -38px;
  left: -38px;
}

.page-header>.sub-header {
  min-width: 35%;
  position: relative;
  height: 80px;
  background: url('./header.svg');
  bottom: -50px;
  left: 0px;
  padding-top: 20px;
  padding-left: 60px;
  color: white;
  float: right;
  background-size: cover;
}

.page-header>.sub-header-title {
  width: 20%;
  position: relative;
  height: 80px;
  bottom: -50px;
  left: 85px;
  float: left;
}

.page-header>.sub-header-title>h2 {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.page-header>.sub-header-title>h3 {
  text-align: center;
  background: #c070cc;
  padding: 10px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
}

.page-root>.page-content {
  width: 100%;
  padding: 25px 50px;
  max-height: 100%;
}

.page-title {
  margin-top: 20px;
  font-weight: bolder;
  display: block;
}

td.head>span.subtask,
td.head>span.submilestones {
  padding-left: 30px;
}

/* table {
  display: table;
  border-spacing: 2px;
}

table {
  width: 100%;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-bottom: 1px solid #d0d0d0;
} */

td>span.team {
  background: #ffd5b1;
  padding: 4px;
  border-radius: 7px;
  color: #ea9c3a;
  padding-left: 10px;
  padding-right: 10px;
}

td>span.badge-leader {
  background: #b2f2d0;
  padding: 4px;
  border-radius: 7px;
  color: #acca4a;
  padding-left: 10px;
  padding-right: 10px;
}


.estimate {
  font-weight: bold;
  background: #7465ad;
  padding: 9px;
  /* border-radius: 15px; */
  margin-top: 5px;
  margin: 10px;
}

span.realisasi {
  font-weight: bold;
  background: #ff9941;
  padding: 15px;
  border-radius: 15px;
}

.card-abu {
  background: #d7d9d9;
  min-height: 50%;
  max-height: 577px;
  width: 464px;
  padding-left: 20px;
  padding-bottom: 20px;
}

span.tipep {
  background: #67a0c8;
  padding: 5px;
  color: #1074ba;
  font-size: 10pt;
  border-radius: 6px;
}

span.waktup {
  background: #aa90e2;
  padding: 5px;
  color: #1074ba;
  border-radius: 6px;
}

span.status-delay {
  background: #ecb88a;
  padding: 5px;
  color: #c58f37;
  border-radius: 6px;
}

span.header-st {
  font-weight: bold;
}

div.milestones {
  background: #ff9941;
  color: white;
  padding: 8px;
  height: 35px;
  width: 35px;
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  /* margin: 9px; */
}

td.milestones,
td.submilestones {
  width: 40px;
}

div.submilestones>span,
div.milestones>span {
  position: absolute;
  top: 12px;
  left: 12px;
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

div.submilestones {
  background: #ff9941;
  color: white;
  height: 40px;
  width: 40px;
  vertical-align: middle;
  padding: 10px;
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  /* margin: 9px; */
}

div.subtask {
  background: #5867dd;
  color: white;
  height: 25px;
  vertical-align: middle;
  padding: 3px;
}

div.task {
  background: #5867dd;
  color: white;
  height: 25px;
  vertical-align: middle;
  padding: 3px;
}

.vertical-align-middle {
  vertical-align: middle;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 40px;
  vertical-align: middle;
}

td.head {
  background: white !important;
  border: none !important;
  width: 380px;
  padding-right: 10px !important;
}

.no-margin {
  margin: 0px !important;
}

.text-head {
  font-weight: bolder;
}

.font-9 {
  font-size: 10pt;
}

.rpn {
  padding: 10px;
  text-align: center;
  width: 100px;
  /* margin: auto; */
  border-radius: 30px;
  color: white;
}

div.realisasi {
  background: #36a3f7;
  color: white;
  padding: 4px;
  vertical-align: middle;
  font-size: 13pt;
}

div.realisasi.milestones,
div.realisasi.submilestones {
  background: #f96914;
}

@page {
  size: landscape;
}



/* printPDF */
/* @page { margin: 0 } */
#print {
  margin: 0;
  font-family: "IPAexGothic", sans-serif;
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}


/** Paper sizes **/
#print.A3 .sheet {
  width: 297mm;
  height: 419mm
}

#print.A3.landscape .sheet {
  width: 420mm;
  height: 296mm
}

#print.A4 .sheet {
  width: 210mm;
  height: 296mm
}

#print.A4.landscape .sheet {
  width: 297mm;
  height: 209mm
}

#print.A5 .sheet {
  width: 148mm;
  height: 209mm
}

#print.A5.landscape .sheet {
  width: 210mm;
  height: 147mm
}

#print.letter .sheet {
  width: 216mm;
  height: 279mm
}

#print.letter.landscape .sheet {
  width: 280mm;
  height: 215mm
}

#print.legal .sheet {
  width: 216mm;
  height: 356mm
}

#print.legal.landscape .sheet {
  width: 357mm;
  height: 215mm
}

/** Padding area **/
.sheet.padding-10mm {
  padding: 10mm
}

.sheet.padding-15mm {
  padding: 15mm
}

.sheet.padding-20mm {
  padding: 20mm
}

.sheet.padding-25mm {
  padding: 25mm
}

/** For screen preview **/
@media screen {
  body {
    background: #E0E0E0;
    height: 100%;
  }

  .sheet {
    background: #FFFFFF;
    /* margin: 5mm auto; */
    padding: 5mm 0;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  #print.A3.landscape {
    width: 420mm
  }

  #print.A3,
  #print.A4.landscape {
    width: 297mm
  }

  #print.A4,
  #print.A5.landscape {
    width: 210mm
  }

  #print.A5 {
    width: 148mm
  }

  #print.letter,
  #print.legal {
    width: 216mm
  }

  #print.letter.landscape {
    width: 280mm
  }

  #print.legal.landscape {
    width: 357mm
  }
}