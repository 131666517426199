.i-circle {
    border: 2px solid white;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    overflow: hidden;
    display: inline-block;
}
.waiting {
    background-color: #423A6F;
}
.card-info {
    padding: 1rem 1rem 0.5rem 1rem;
    margin-bottom: 1rem;
    color: #fff;
}
.bg-violet-1 {
    background: #5F58B6 !important;
}
.bg-violet-3 {
    background: #8A85D3 !important;
}

.ideasiRiset{
    display: block;
    text-align: center;
    padding: 7px;
    background-color: #c4c5d6;
}
.ideasiRiset:hover{
    background-color: #007bff;
    color: white;
}
.ideasiRisetRight{
    display: block;
    color: #5867dd;
    padding: 7px;
    text-align: center;
    background-color: white;
    border: 1px solid #5867dd;
}
.rounded-left{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.rounded-right{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.cstmbgPrimary{
    background-color: #326BD5;
}

.cstm-table tr > td{
    border-top: none !important;
    padding: 0px !important;
}
.title-box{
    width: 100px !important;
    word-wrap: break-word;
}
