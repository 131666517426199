.text-bold-patent{
    font-weight: 700;
}

.label-patent{
    width: 70px;
}
.cstm-upload .dx-fileuploader-input-wrapper{
    padding-top: 0;
}
.verse {
    fill: darkGreen;
    stroke: #031;
    word-spacing: 2px;                             
}
.verse > tspan:nth-child(2n) {                     
    fill: navy;
    stroke: #013;
}
.center-text-doughnat{
    font-size: 8pt;
}
@media only screen and (min-width: 1000px) and (max-width: 1400px) {    
    .center-text-doughnat{
        font-size: 6pt;
    }
}
.header_event_display{
    height: 119px;
    background-image: url('/public/svg/eventimg.svg');
    background-repeat: no-repeat;
    background-position-x: right;
}
.header_achievement_display{
    /* height: 119px; */
    background-image: url('/public/svg/achivemenetimg.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
}
.patent-detail-margin{
    margin-bottom: 50px !important;
}
.bgActiveVsContract{
    background-image: url('/public/images/active_contract.png');
    background-repeat: no-repeat;
    background-position: bottom 30px right;
    background-size: auto;
}
.textTitleAchievement{
    color: black !important;
}
.textTitleAchievement:hover{
    color: #337ab7 !important;
}
.inv-det-name{
    font-size: 12pt;
    font-weight: bold;
}
.inv-det-exp{
    width: 156px;    
}