.headerCustomRisk .dx-header-row{
    background-color: #eae9ec;
    border :white;
    font-family: 'Poppins';
    font-size: 9pt;
    font-weight: bold;
    color: #808286;
} 
.textalign .dx-cell-focus-disabled{
    text-align: center !important;
}