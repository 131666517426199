.customInput{
    display: flex;
}
.customIconShow{
    position: absolute;
    top: 25%;
    right: 30px;
    cursor: pointer;
}
.dx-invalid.dx-lookup .dx-lookup-field:after,  
.dx-invalid.dx-texteditor .dx-texteditor-container:after {  
    display: none;  
}  
.showpass-change{
    right: 20px;
    position: absolute;
    height: 100%;
    display: inline-block;
    text-align: center;
    top: 0;
    cursor: pointer;
    padding-top: 0.55rem;
}
