.elipsis-text-grid {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
}

.formSearch{
    position: relative;
    padding: 0;
    width: 100%;
}
.formSearchIcon{
    right: 10px;
    position: absolute;
    height: 100%;
    display: inline-block;
    text-align: center;
    top: 0;
    padding-top: 0.55rem;
}
.bg-yellow {
    color: #ffff;
    background-color: yellow;
}

.bg-purple {
    color: white !important;
    background-color: #716ACA !important;
}

.bg-pink {
    color: white mp !important;
    background-color: #C070CC !important;
}

.bg-blue {
    color: white;
    background-color: #006CB8 !important;
}

.bg-metal {
    background-color: #c4c5d6 !important;
    color: #ffffff;
}

.bg-accent {
    background-color: #00c5dc;
    color: #ffffff;
}

.bg-gold {
    background-color: #BBAB41;
    color: #ffffff;
}

.bg-disable {
    background-color: #f3f6f9 !important;
}
.bg-red-dark {
    background-color: #a80014 !important;
}
.bg-red {
    background-color: #F64E61 !important;
}

.bg-light-metal {
    background-color: rgba(196, 197, 214, 0.2);
}

.bg-light-accent {
    background-color: rgba(0, 197, 220, 0.2);
}

.bg-light-gold {
    background-color: rgba(187, 171, 65, 0.2);
}

.bg-light-version {
    background-color: rgba(113, 106, 202, 0.2) !important
}

.bg-light-yellow {
    background-color: rgba(255, 255, 0, 0.2) !important;
}

.bg-light-purple {
    background-color: rgba(111, 66, 193, 0.2) !important;
}

.bg-light-blue {
    background-color: rgba(135, 75, 230, 0.2) !important;
}

.text-metal {
    color: #c4c5d6;
}

.text-blue {
    color: #006CB8;
}

.text-accent {
    color: #00c5dc;
}

.text-gold {
    color: #BBAB41;
}

.text-version {
    color: #716aca !important;
}

.text-yellow {
    color: yellow !important;
}

.text-purple {
    color: purple !important;
}

.border-red {
    background-color: #f8eaec !important;
    border: 1px solid #F64E61 !important;
    color: #F64E61 !important;
}

.border-blue {
    background-color: #e4f3fe !important;
    border: 1px solid #006CB8 !important;
    color: #006CB8 !important;
}

.floating-footer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    position: fixed;
    bottom: 0px;
    left: 70px;
    z-index: 2;
    min-height: 46px;
    width: calc(100% - 70px)
}

.floating-footer-nolayout {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    position: fixed;
    bottom: 0px;
    z-index: 2;
    left: 0;
    min-height: 46px;
    width: 100%
}

.floating-sticky {
    position: sticky;
    padding: 0.5rem;
    bottom: 10px;
}

.icon-header {
    height: 32.5px;
    width: 32.5px;
}

.svg-icon-blue svg g [fill] {
    transition: fill 0.3s ease;
    fill: #874BE6 !important;
}

.custom-dx-fileuploader-file-name .dx-fileuploader-file-name {
    white-space: normal !important;
    font-size: 8pt;
    font-family: 'Poppins';
}

.custom-dx-fileuploader-file-name .dx-fileuploader-input-container {
    white-space: normal !important;
    font-size: 8pt;
    font-family: 'Poppins';
}

.custom-dx-fileuploader-file-name .dx-fileuploader-file-status-message {
    white-space: normal !important;
    font-size: 8pt;
    font-family: 'Poppins';
}

.custom-dx-fileuploader-file-name .dx-button-content {
    white-space: normal !important;
    font-size: 8pt;
    font-family: 'Poppins';
}

.dx-fileuploader-wrapper {
    padding: 0 !important;
}

.dx-fileuploader-input-wrapper {
    border: none !important;
}

.dx-fileuploader-files-container {
    padding: 0 !important;
}

.font-bold {
    font-weight: bold !important;
}

.dx-texteditor.dx-editor-outlined {
    background: #fff;
    border: 1px solid #E4E6EF;
    border-radius: 4px;
    padding: 0;
}

.dx-texteditor.dx-state-active.dx-editor-filled,
.dx-texteditor.dx-state-active.dx-editor-outlined,
.dx-texteditor.dx-state-active.dx-editor-underlined,
.dx-texteditor.dx-state-focused.dx-editor-filled,
.dx-texteditor.dx-state-focused.dx-editor-outlined,
.dx-texteditor.dx-state-focused.dx-editor-underlined {
    border-color: #3699FF !important;
}

.style-view-disable {
    font-size: 8pt;
    line-height: 1.35;
    border-radius: 0.28rem;
    display: block;
    width: 100%;
    padding-top: 0.55rem;
    padding-right: 0.75rem;
    padding-bottom: 0.55rem;
    padding-left: 0.75rem;
    color: #3F4254;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    background-color: #F3F6F9;
    opacity: 1;
    white-space: pre-line;
}

.dx-fileuploader-input-wrapper {
    padding-top: 0px !important;
}

.link-style {
    color: black;
    cursor: pointer;
}

.link-style:hover {
    color: #3699FF;
}

.border-right-custom {
    border-right: 1px solid #EBEDF3;
}

.border-left-custom {
    border-left: 1px solid #EBEDF3;
}

.tagify {
    width: 100%;
}

@media only screen and (max-width : 479px) {
    .border-right-custom {
        border-right: none;
    }

    .border-left-custom {
        border-left: none;
    }

    .floating-footer {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        min-height: 46px;
        width: calc(100%)
    }

    .card-columns {
        column-count: 1 !important;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .floating-footer {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        min-height: 46px;
        width: calc(100%)
    }

    .card-columns {
        column-count: 2 !important;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .floating-footer {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        min-height: 46px;
        width: calc(100%)
    }

    .card-columns {
        column-count: 3 !important;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
}

@media only screen and (min-width: 992px) {
    .card-columns {
        column-count: 4 !important;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
}