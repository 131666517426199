.doc-export-style {
  font-size: 8pt;
  font-family: Arial, sans-serif;
  font-style: normal;
  color: black;
}

.doc-export-style .header-doc>div>span {
  font-size: 11pt;
  font-weight: 700;
  text-align: center;
  display: block;
}

.doc-export-style .row-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  padding: 5px;
}
.doc-export-style .row-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
  padding: 5px;
}


.doc-export-style .row-2>span {
  font-size: 6pt;
}

.doc-export-style .row-4 {
  display: flex;
  width: 100%; 
  border-color: black;
  height: 110px;
}
.doc-export-style .row-4>div>div {
  padding: 12px 5px 5px 5px;
}

.doc-export-style .row-7 {
  display: flex;
  flex-direction: column;
  height: 110px;
  padding: 5px;
}

.doc-export-style .row-7 .content-left-row-7 {
  text-align: left;
  margin-left: 20px;
}
.doc-export-style .row-7 .content-right-row-7 {
  text-align: left;
  margin-left: 60px;
}

@font-face {
  font-family: "Custom";
  src: local('Arial Narrow Bold') format("truetype");
}

.history-status-export-tr1 {
  height: 44px;
}

.history-status-export-tr1>td {
  width: 50%;
  padding: 0 !important;
}

.history-status-export-tr1>td>div>span {
  font-family: "Custom", Arial, sans-serif, sans-serif;
  font-weight: 700;
  font-size: 12pt;
}

.history-status-export-tr1>td>div {
  height: 100%;
  align-items: center;
  text-align: center;
  margin: auto;
}

.history-status-export-tr2>td {
  height: 0;
}

.history-status-export-tr2 {
  font-size: 8pt;
}

.history-status-export-tr3 {
  font-size: 8pt;
}

.history-status-export-tr3>td {
  font-size: 8pt;
}

.table-cs table {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.table-cs table>tbody>tr {
  border-top: 1px solid black;
}

.table-cs table>tbody>tr>td {
  border-right: 1px solid black;
}



.page-reporting-size {
  width: 21cm;
  height: 29.7cm;
  margin-top: 15px;
  padding: 1cm;
}


#chart {
  height: 440px;
}

/* @page { margin: 0 } */
#print {
  margin: 0;
  font-family: "Poppins";
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

.testing-font {
  font-family: 'Times New Roman', Times, serif;
}

.testing-font p {
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
  font-weight: bold;
}


/** Paper sizes **/
#print.A3 .sheet {
  width: 297mm;
  height: 419mm
}

#print.A3.landscape .sheet {
  width: 420mm;
  height: 296mm
}

#print.A4 .sheet {
  width: 210mm;
  height: 296mm
}

#print.A4.landscape .sheet {
  width: 297mm;
  height: 209mm
}

#print.A5 .sheet {
  width: 148mm;
  height: 209mm
}

#print.A5.landscape .sheet {
  width: 210mm;
  height: 147mm
}

#print.letter .sheet {
  width: 216mm;
  height: 279mm
}

#print.letter.landscape .sheet {
  width: 280mm;
  height: 215mm
}

#print.legal .sheet {
  width: 216mm;
  height: 356mm
}

#print.legal.landscape .sheet {
  width: 357mm;
  height: 215mm
}

/** Padding area **/
.sheet.padding-10mm {
  padding: 10mm
}

.sheet.padding-15mm {
  padding: 15mm
}

.sheet.padding-20mm {
  padding: 20mm
}

.sheet.padding-25mm {
  padding: 25mm
}

/** For screen preview **/
@media screen {
  body {
    background: #E0E0E0;
    height: 100%;
  }

  .sheet {
    background: #FFFFFF;
    /* margin: 5mm auto; */
    padding: 5mm 0;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  #print.A3.landscape {
    width: 420mm
  }

  #print.A3,
  #print.A4.landscape {
    width: 297mm
  }

  #print.A4,
  #print.A5.landscape {
    width: 210mm
  }

  #print.A5 {
    width: 148mm
  }

  #print.letter,
  #print.legal {
    width: 216mm
  }

  #print.letter.landscape {
    width: 280mm
  }

  #print.legal.landscape {
    width: 357mm
  }
}