.custom-style > 
.dx-datagrid > 
.dx-datagrid-headers > 
.dx-datagrid-content >
table > tbody > tr > td{
    background: #006CB8;
    color: white;
    /* text-align: left !important; */
    border: 1pt #006CB8 solid;
}

.custom-style .dx-datagrid .dx-header-filter{
    color: white;
}
.custom-style .dx-datagrid-content >
table > tbody > tr:nth-child(even) {
    background: white;
 }
.custom-style-master
.dx-datagrid-rowsview 
.dx-master-detail-row:not(.dx-datagrid-edit-form) 
.dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
    background-color: #eae9ec !important;
}
.custom-style .dx-datagrid-total-footer 
 /* .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed > */
 table > tbody > tr > td {
    background: #006CB8;
    color: white;
    /* text-align: left !important; */
    border: 1pt #006CB8 solid;
}
.custom-style .dx-datagrid-total-footer 
 /* .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed > */
 table > tbody > tr > td > div {
    background: #006CB8;
    color: white;
    /* text-align: left !important; */
    border: 1pt #006CB8 solid;
}

