.customShadow {
    box-shadow: 1px 1px 1px 0px silver;
-webkit-box-shadow: 1px 1px 5px 0px silver;
-moz-box-shadow: 1px 1px 1px 0px silver;
}
.myContainer{
    margin-bottom: 1.5rem;
    height: 100%;
}

.ctsmFilterTitle{
    font-weight: bold;
    font-size: 12px;
    display: block;
}

.ctsmFilterSubTitle{
    font-size: 9px;
    font-weight: normal;
    display: block;
}