.bg-detail-land{
  background: url('./images/bg-land-detail.svg');
  background-repeat: no-repeat;
  background-size: 100% 300px;
  background-position: top;
}

.App-bg {
  /* background-image: url('./images/background.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.input-group-rounded .input-group-field {
  /* border-radius: 0 5000px 5000px 0; */
  padding-left: 1rem;
}

.input-group-rounded .input-group-icon {
  /* border-radius: 5000px 0 0 5000px; */
  font-size: 0.8rem;
}

.input-group-rounded .input-group-field:focus {
  border: 1px solid #E4E6EF;
}

.event-card-desc {
  line-height: 1.5rem;
  height: 7.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-card {
  object-fit: contain;
  height: 180px;
}


.button-cs-landingpage {
  cursor: pointer;
  /* now added in my edit */
  display: inline-block;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #2E6BB4;
  color: white;
  background-color: #2E6BB4;
  transition: color .5s;
  position: relative;
  /* now changed in my edit */
  z-index: 1;
  /* because text to be placed over the background */
  overflow: hidden;
  /* because background of div not to be overflow from button. */
}

.background-button-cs-landingpage {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* top-left and transform are for putting div in the middle of button.*/
  background-image: linear-gradient(#3699FF, #3699FF);
  transition: .5s;
  z-index: -1;
  border-radius: 50%;
}

.button-cs-landingpage:hover {
  color: white;
  border-color: #3699FF;
}

.button-cs-landingpage:hover .background-button-cs-landingpage {
  width: 150%;
  height: 500%;
  /* 150% and 500% because div completely to be fill the space of the button.*/
}

.center-corner {
  background-position: 50% 50%;
}

.button-cs-produk {
  cursor: pointer;
  /* now added in my edit */
  display: inline-block;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid white;
  color: white;
  font-size: 12px;
  font-family: arial;
  background-color: transparent;
  transition: color .5s;
  position: relative;
  /* now changed in my edit */
  z-index: 1;
  /* because text to be placed over the background */
  overflow: hidden;
  /* because background of div not to be overflow from button. */
}

.background-button-cs-produk {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* top-left and transform are for putting div in the middle of button.*/
  background-image: linear-gradient(white, white);
  transition: .5s;
  z-index: -1;
  border-radius: 50%;
}

.button-cs-produk:hover {
  color: #2E6BB4;
}

.button-cs-produk:hover .background-button-cs-produk {
  width: 150%;
  height: 500%;
  /* 150% and 500% because div completely to be fill the space of the button.*/
}


.button-cs-event {
  cursor: pointer;
  /* now added in my edit */
  display: inline-block;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #2E6BB4;
  color: #2E6BB4;
  font-size: 12px;
  font-family: arial;
  background-color: transparent;
  transition: color .5s;
  position: relative;
  /* now changed in my edit */
  z-index: 1;
  /* because text to be placed over the background */
  overflow: hidden;
  /* because background of div not to be overflow from button. */
}

.background-button-cs-event {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* top-left and transform are for putting div in the middle of button.*/
  background-image: linear-gradient(#2E6BB4, #2E6BB4);
  transition: .5s;
  z-index: -1;
  border-radius: 50%;
}

.button-cs-event:hover {
  color: white;
}

.button-cs-event:hover .background-button-cs-event {
  width: 150%;
  height: 500%;
  /* 150% and 500% because div completely to be fill the space of the button.*/
}

/* .container-landing-page{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575.98px) { .container-landing-page{
  ma
} }


@media (min-width: 576px) and (max-width: 767.98px) { ... }


@media (min-width: 768px) and (max-width: 991.98px) { ... }


@media (min-width: 992px) and (max-width: 1199.98px) { ... }


@media (min-width: 1200px) { ... } */