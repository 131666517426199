.btn-carousel-anual-rpt {
    cursor: pointer;
    width: 150px;
    position: absolute;
    bottom: 0px;
    left: 7px;
    color: white;
    background-color: #2E6BB4;
    height: 35px;
    align-items: center;
    text-align: center;
    display: flex;
}
.btn-carousel-anual-rpt-icon{
    display: none !important;
}
.btn-carousel-anual-rpt:hover{
    background-color: #3699FF;
}
.btn-carousel-anual-rpt:hover .btn-carousel-anual-rpt-icon{
    display: inline-block !important;
}

.card-land-produk{
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
}
.card-land-produk:hover{
    box-shadow: 0px 3px 6px #006bb8;
}

.font-color-cs {
    color: #2E6BB4;    
}

.cs-crsl-prdk {
    margin-bottom: 40px;
    overflow: hidden;
}

.custom-bg-product {
    min-height: 400px;
    background: url('./image/LP-Banner utama.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    background-color: white;
    padding-top: 10px;
}

.custom-bg-achievement {
    width: 100%;
    height: 300px;
    object-fit: cover;
    position: absolute;
    top: 0;
}

@media only screen and (max-width: 768px) {
    .custom-bg-product {
        background-size: cover;
    }
}

.custom-carousel-overflow .carousel.carousel-slider {
    overflow: visible;
}

.custom-carousel-control-dots .carousel .control-dots {
    bottom: -30px;
    text-align: end;
}

.custom-carousel-dots .carousel .control-dots .dot {
    opacity: 1;
    background: gray;
}

.custom-carousel-dots .carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    opacity: 1;
    background: red;
}

.test-carousel .control-dots {
    bottom: 40%;
    width: 18.5px;
    right: 10px;
    display: grid;
}

.test-hovearble {
    height: 35px;
    transition: height 0.5s;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    cursor: pointer;
}

.test-hovearble>div {
    color: transparent;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.test-hovearble>div>i {
    border: 2px solid transparent;
    color: transparent;
    transition: color 1s, border-color 1s;
}

.test-hovearble>p {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    text-align: center;
}

.test-hovearble>span {
    color: transparent;
    transition: color 1s;
    padding-left: 10px;
    padding-right: 10px;
}

.test-hovearble:hover {
    height: 180px;
}

.test-hovearble:hover>span {
    color: white;
}

.test-hovearble:hover>div {
    color: white;
}

.test-hovearble:hover>div>i {
    color: white;
    border: 2px solid white;
}


.title-spiirit {
    font-weight: bold;
    font-size: 18pt;
    display: block;
    margin-bottom: 20px;
    margin-top: 40px;
}

.icon-product {
    width: 150px;
    height: 150px;
}

.icon-achievement {
    width: 100px;
    height: 100px;
    top: -50px !important;
    right: 330px !important;
}

.icon-event {
    width: 150px;
    height: 150px;
}

/* .card-footer-product {
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 1rem;
    left: 0;
    margin-left: 1rem;
} */
.subtractionIcon {
    position: absolute;
    top: 50%;
    left: 30px;
}

.btn-cs {
    background-color: #006CB8;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: white;
}

.btn-cs-outline-prim {
    color: #006CB8 !important;
    border-color: #006CB8 !important;
}

.btn-cs-outline-prim:hover {
    color: white !important;
    background-color: #006CB8 !important;
}

.text-truncate-container {
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.footer-card-product {
    height: 20px;
}

.footer-card-product div {
    padding-right: 10px;
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding-left: 10px;
    left: 0;
}

.footer-card-event {
    height: 50px;
}

.footer-card-event div {
    padding-right: 5px;
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding-left: 5px;
    left: 0;
}

@media only screen and (min-width:451px) and (max-width: 850px) {
    .icon-achievement {
        right: 230px !important;
    }
}

@media only screen and (max-width: 450px) {
    .icon-achievement {
        right: 130px !important;
    }
}

.image-1{
    height: 600px;
    /* width: 100%; */
}
.image-2{
    height: 300px;
    width: 100%;
}
.image-3{
    height: 350px;
    width: 100%;
}
@media only screen and (min-width:451px) and (max-width: 850px) {
    .image-1 {
        height: 400px;
    }
}

@media only screen and (max-width:450px) {
    .image-1 {
        height: 200px;
    }
    .image-2{
        height: 200px;
    }
    .image-3{
        height: 200px;
    }
}