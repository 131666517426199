.cstm-switch-container .dx-switch-container{
    border-radius: 25px !important;
}
.cstm-switch-btn .dx-switch-handle {
    height: 14px;
    margin-top: 4px;
    border-radius: 25px;
}
.cstm-switch-btn-before .dx-switch-handle::before {
    border-radius: 25px;
}
.switchOn .dx-switch-on {
    color: transparent;
}
.switchOff .dx-switch-off {
    color: transparent;
}