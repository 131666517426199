.footer-css {
    /* display: block;
    position: absolute; */
    bottom: 0px;
    width: 100%;
    left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 24px;
  }
  