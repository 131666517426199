.bordered {
    border:1px solid black;
}

.card-menu {
    color: white;
    min-height: 150px;
    border-radius: 7px;
    padding: 5px;
    box-shadow: 0px 0px 1px 0px #000000;
}

.card-menu > .img-menu {        
    margin-top: 25px;
    margin-bottom: 30px;
}

.img-menu > img {
    width:70px;
    height:70px
}


.img-menu {
    text-transform: uppercase;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
.card-menu:hover > .img-menu {
    animation-name: bounce;
    -moz-animation-name: bounce;
}

.card-menu > .title-menu {
    margin-bottom:30px;
}

a.link-card:hover{
    text-decoration:none;
}

.card-home {
    box-shadow: 0px 0px 1px 0px #000000;
    background: white;
    min-height: 100px;
    border-radius: 5px;
    padding:10px;
}

.card-home > .content-berita {
    height : 450px;
    width:100%;
    padding-left:5px;
    margin-top:15px;
    overflow-x:hidden;
    overflow-y:scroll;
}


/* width */
.content-berita::-webkit-scrollbar {
    width: 7px;
    border-radius:2px;
}

/* Track */
.content-berita::-webkit-scrollbar-track {
    background: none;
    width: 7px;
}

/* Handle */
.content-berita::-webkit-scrollbar-thumb {
    background: none;
}

.content-berita:hover::-webkit-scrollbar-thumb {
    background: #d0d0d0;
}

    /* Handle on hover */
    .content-berita::-webkit-scrollbar-thumb:hover {
        background: #a7a3a3;
    }

.card-home > .content-berita > .body-berita {
    width: 100%;
    border-bottom: 1pt solid #e0d9d9;
    padding: 10px;
}

.img-berita {
    width: 100%;
    border-radius: 7px;
    min-height: 115px;
    border-radius: 5px;
}

.content-event {
    width:100%;
    padding:5px;
}

.img-event {
    width: 100%;
    height: 140px;
}

.img-populer {
    width: 100%;
    border-radius:20px;
    height:165px;
}

.elipsis-title {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom:10px;
}
.event-bg{
    position: absolute;
    top: -50px;
    right: -100px;
    /* background-image: url('/homeasset/home_event.svg');
    background-clip: content-box !important;
    background-repeat: no-repeat;
    background-position: top right; */
}

.label-populer {
    color: white;
    padding: 5px;
    /* position: absolute; */
    z-index: 99;
    border-radius: 27px;
    min-width: 240px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
/* Custom style Button and Background */
.bg-orange{
    background-color: #F08044 !important;
}

.btn-orange {
    color: white !important;
    background-color: #F08044 !important;
    border-color: #F08044 !important; /*set the color you want here*/
}
.btn-orange:hover, .btn-orange:focus, .btn-orange:active, .btn-orange.active, .open>.dropdown-toggle.btn-orange {
    color: white !important;
    background-color: #f26d26 !important;
    border-color: #f26d26 !important; /*set the color you want here*/
}
.font-card{
    font-size: 8pt !important;
}


@keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    60% {
        -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
    .event-bg{
        position: absolute;
        top: -50px;
        right: -100px;
        width: 300px;
        height: 200px;
    }
}