.footer-card {
    border-radius: 80px 80px 0px 0px;
    box-shadow: 0px -3px 20px #0000001A;
    background-color: #006CB8;
    /* background-image: url('./image/background_footer.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    background-position: bottom;
}

.footer-card-new {
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
}

@media only screen and (max-width: 870px) {
    .footer-card-new {
        height: 100%;
    }
}

.footer-base-card {
    position: relative;
}

.icon-wrapper {
    width: 180px;
}

.icon-img {
    max-width: 100%;
    height: auto;
}