
.swa-chart6-bg{
    width: 400px;
    position: absolute;
    right: 0;
}
@media only screen and (max-width: 479px) {
    .swa-chart6-bg{
        display: none;
    }
}