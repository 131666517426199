

.bg-pp {
    background: url("./Group 6063.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-size: cover;
    background-position: center;
}

.page-root-pp {
    background: #ffffff;
    position: relative;
    width: 1366px;
    min-height: 965px;
    margin-bottom: 15px !important;
    flex: none;
    padding-top: 38px;
    padding-bottom: 100px;
    page-break-before: always;
    margin: auto;
    padding-left: 35px;
    padding-right: 35px;
    box-shadow: 2px 0px 11px 0px lightgrey;
}

.cover-pp {
    background: url("./cover.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-size: cover;
    background-position: center;
}

.page-root-pp > .header-pp {
    width: 1366px;
    position: relative;
    height: 80px;
    background: #95c93d;
    top: -38px;
    left: -35px;
}

.header-pp > .sub-header-pp {
    width: 35%;
    position: relative;
    height: 80px;
    background: linear-gradient(90deg, #0d6db6 85%, #cb2027 15%);
    bottom: -35px;
    left: 888px;
    padding-top: 20px;
    padding-left: 20px;
    color: white;
}

.page-root-pp > .page-content-pp {
    width: 100%;
    padding: 5px;
    max-height: 100%;
}

.page-title-pp {
    margin-top: 20px;
    font-weight: bolder;
    font-size: 11pt;
    display: block;
}

.estimate {
    font-size: 14pt;
    font-weight: bold;
    background: #7465ad;
    padding: 9px;
    /* border-radius: 15px; */
    margin-top: 5px;
    margin: 10px;
}


.text-head{
    /* font-size:12pt; */
    font-weight:bolder;
}
