#data-grid-demo {
    min-height: 700px;
}

#gridContainer {
    padding-top: 45px;
}

#gridDeleteSelected {
    position: absolute;
    z-index: 1;
    right: 0;
    margin: 1px;
    top: 0;
}

#gridDeleteSelected .dx-button-text {
    line-height: 0;
}

.striped>.dx-datagrid {
    border: none !important;
}

/* .striped > 
.dx-datagrid > 
.dx-datagrid-rowsview >  */
/* .dx-datagrid-content>table>tbody>tr:nth-child(even) {
    background: #faf9fc;
    color: #808286;
} */


/* .striped > 
.dx-datagrid > 
.dx-datagrid-rowsview >  */
.dx-datagrid-content>table>tbody>tr>td {
    /* text-align: left !important; */
    border: 1pt #faf9fc solid;
}

/* .striped > 
.dx-datagrid > 
.dx-datagrid-headers {
    background: #575962;
    color: white;
    text-align: left;
} */


.striped>.dx-datagrid>.dx-datagrid-headers>.dx-datagrid-content>table>tbody>tr>td {
    background: #eae9ec;
    color: #808286;
    /* text-align: left !important; */
    border: 1pt #eae9ec solid;
}

.striped>.dx-datagrid>.dx-datagrid-headers>.dx-datagrid-content>table>tbody>tr>td>div {
    font-weight: bolder;
    font-size: 9pt;
    font-family: 'Poppins';
}

.customColorIcon .dx-button-mode-outlined .dx-icon {
    color: white;
}

.customColotText .dx-button-text {
    color: white;
}

.fontContent tr {
    font-size: 11px;
    font-family: 'Poppins';
}

.btn-export {
    float: right;
    height: 30px;
    margin-right: 10px;
    padding: 5px 20px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
    padding: 0;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    text-align: center !important;
}

tr.dx-header-row>.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
    padding: 0;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    text-align: center !important;
}


.btn-toolbar {
    height: 30px;
    margin-right: 10px;
    padding-top: 5px !important;
}

.footer-grid .dx-datagrid-total-footer>.dx-datagrid-content {
    padding-top: 0px;
    padding-bottom: 0px;
}

.ts>div {
    max-height: 600px;
}