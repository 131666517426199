.textStyleTask {
    background-color: #5867DD;
    text-align: center;
    color: white;
    display: block;
    padding: 5px
}

.task-plan {
    background-color: #006bb8a3;
}

.task-milestone {
    background-color: #F64E61;
}

.textStyleTaskTitle {
    display: block;
    min-height: 24.4px;
    max-height: 24.4px;
    padding: 5px;
}

.textStyleMilestone {
    justify-content: center;
    /* background-color: #FF9941; */
    text-align: center;
    color: white;
    display: inline-flex;
    padding: 7px;
    width: 25px !important;
    height: 25px !important;
    transform: rotateY(0deg) rotate(45deg);
    /* needs Y at 0 deg to behave properly*/
}

.headerCustomGantt .dx-header-row {
    text-align-last: center;
}

.textNull {
    background-color: transparent !important;
}

.innerMilestone {
    transform: rotate(-45deg);
}

.innerTask {
    transform: rotate(0deg);
}

.cstmAlignText .dx-datagrid-content>table>tbody>tr>td {
    text-align: start !important;
}

.grid-ganchart .dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
    vertical-align: middle !important;
}