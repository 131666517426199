.customInfoTotalPrj{
    display: block;
    text-align: center;
    padding: 7px;
    background-color: #326BD5;
}
.customInfoTotalBdgt{
    display: block;
    padding: 7px;
    text-align: center;
    background-color: #326BD5;
}
.cstmBorder-left{
    border-left: 1px solid white;
}
.ctsmRounded-left{
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.ctsmRounded-right{
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.cstm-Active{
    border: 3px solid rgba(50, 107, 213, 0.3) !important	 ;
    background-image: url('/public/images/BG_Aktif.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: rgba(50, 107, 213, 0.5) !important; */
    /* color: white !important; */
    box-shadow: 0 3px 6px rgba(50, 107, 213, 0.5) !important;
}
.cstm{
    background-image: url('/public/images/BG_Inaktif.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}
.cstm-perform-table tr > td{
    border-top: none !important;
}

