.btn-project {
    position: fixed;
    z-index: 999;
    width: 170px;
    height: 48px;
    background: #44E08D 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 8px #0000001A;
    border-radius: 35px;
    opacity: 1;
    color: white;
    bottom: 30px;
    right: 70px;
}

table.project-status{
    margin: 0.5rem 0;
  }
  table.project-status tr td{
    font-size: 0.9rem !important;
    line-height: 1.2rem;
    vertical-align: top;
    padding: 0 0.3rem;
  }

.col-projectdetail{
    width: 50%;
    margin-right: 10px;
}

.col-projectstatus{
    width: 20%;
}

.c-project-base {
    /* top: 214px;
    left: 611px; */
    /* width: 698px;
    height: 572px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000065;
    border-radius: 10px;
    opacity: 1;
    background: linear-gradient(180deg, #369AFF 30%, #ffffff 30%);
    padding: 20px;
}

.c-project-head {
    color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.c-green{color: #44E08D !important}
.c-gray{color: #969696 !important}
.c-white{color: #FFFFFF !important}
.c-light-gray{color: #DCDCDC !important}

.fa-file-text-o:before {
    content: "\f0f6"; }

.c-project-head > span.title {
    font-size: 22pt;
}
.c-project-choice {
    width: 100%;
    height: 100%;
    /* background: url('./background.svg'); */
    opacity: 1;
    background: linear-gradient(169deg, white 50%, #f7f7f7 25%);
    /* border: 1pt solid gray; */
    border-radius: 8px;
    padding: 5px;
    box-shadow: 0px 3px 6px #00000029;
    /* box-shadow: 0px 0px 19px 4px rgb(0 0 0 / 10%); */
    cursor: pointer;
}

.title-choice {
    /* text-align: left; */
    font: normal normal bold 20px/24px Roboto;
    letter-spacing: 0px;
    color: #444444;
    opacity: 1;
}


.btn-create{
    width: 261px;
    height: 45px;
    color: #3699FF;
    background-color: #E1F0FF;
    border-color: transparent;
    box-shadow: 0px 3px 6px #00000029;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border-radius: 2px; */
    opacity: 1;
    /* background: #969696 0% 0% no-repeat padding-box; */
}

/* .btn-create.disabled{
    background: #969696 0% 0% no-repeat padding-box;
} */

.btn-create.active{
    background: #369AFF 0% 0% no-repeat padding-box;
    cursor: pointer;
}

.choice-active {
    color:  #814CEA;
}


.c-project-choice.active {
    box-shadow: 0px 0px 9px 1px #369AFF;
}


