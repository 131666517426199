.tab-stepTwo{
    max-height: 500px;
    width: 200px;
    overflow: auto;
    flex: none;
}
.tab-stepTwo::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.tab-stepTwo::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px whitesmoke;
    border-radius: 10px;
}

.tab-stepTwo::-webkit-scrollbar-thumb {
    background: silver;
    border-radius: 10px;
}

.tab-stepTwo::-webkit-scrollbar-thumb:hover {
    background: silver;
}

.csCarousel .carousel .slide{
    text-align: left;
}
.komoditi{
    background: white;
    color: #181C32;
}
.komoditi.active{
    background: #F3F6F9;
    color: #3699FF;
}
.komoditi:hover{
    color: #3699FF;
    background: #F3F6F9;
}
.tblecs.table-bordered > thead > tr > th{
    background-color: gray;
    color: white;
  }
  /* .tblecs.table-bordered > tbody > tr > td{
    border:1px solid gray;
} */