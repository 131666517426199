.m-subheader {
    padding: 10px 20px 0 5px;
}
.m-subheader .m-subheader__title.m-subheader__title--separator {
    border-right: 1px solid #e2e5ec;
}
.titlePage {
    display: inline-block;
    padding: 7px 15px 7px 0;
    font-weight: 300;
    font-size: 12pt;
    font-weight: 1000;
    vertical-align: middle;
    margin: 0 15px 0 0;
    color: #3f4047;
    /* border-right: 1px solid #B2B2B2; */
}
.textsubtitle{
    font-size: 8pt !important;
}
.containerGroup{
    display: flex;
    align-items: center;
}
.controlerStyle{
    padding-left: 5px;
    padding-right: 5px;
}
