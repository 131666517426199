.custom-style>.dx-datagrid>.dx-datagrid-headers>.dx-datagrid-content>table>tbody>tr>td {
    background: #006CB8;
    color: white;
    /* text-align: left !important; */
    border: 1pt #006CB8 solid;
}

.custom-style .dx-datagrid .dx-header-filter {
    color: white;
}

.custom-style .dx-datagrid-content>table>tbody>tr:nth-child(even) {
    background: #D4E6F2;
    color: #808286;
}

.custom-dropdown-download .dx-button-has-icon .dx-button-content {
    padding: 0.25rem !important;
}

.custom-dropdown-download .dx-button-content {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.detail-popup {
    background-color: transparent;
    backdrop-filter: brightness(0.7) blur(3px);
    overflow: scroll;
}

.form-nama-partner {
    font-size: 10px;
}

.text-content-partners {
    font-size: 8pt !important;
}

.text-content-partners-grp .MuiTypography-body1 {
    font-size: 8pt !important;
}

.information-icon-kerjasama {
    height: 15px;
    font-weight: 600;
    width: 15px;
    border-radius: 50%;
    display: inline-flex;
    background-color: white;
    border-color: #B5B5C3;
    border: 1.3px solid;
    color: #B5B5C3;
    justify-content: center;
}

.information-icon-kerjasama:hover {
    color: white;
    background-color: #006CB8;
    border-color: #006CB8;
}