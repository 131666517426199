.input-tag{
    border: solid 1px gainsboro;
    border-radius: 5%;
}
.input-tag:active{
    border-color: silver;
    border-width: 1px;
}
.input-tag:focus{
    outline:none;
}