.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
.content-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9F9F9;
  padding: 1em;
  overflow: hidden;
}
.borderFirst{
  position: relative;
  left: 50%;
  top: 25px;
  border-top: 2px solid #ebedf3;
}
.borderLast{
  position: relative;
  right: 50%;
  top: 25px;
  border-top: 2px solid #ebedf3;
}
.borderBetween{
  position: relative;
  top: 25px;
  border-top: 2px solid #ebedf3;
}
.bg-expertise{
  background-color:rgba(172, 196, 41, 0.2) !important
}
.bg-patent{
  background-color: rgb(192, 112, 204, 0.2) !important;
}
.svg-icon-patent svg g [fill] {
  transition: fill 0.3s ease;
  fill: #C070CC !important;
}
.svg-icon-expertise svg g [fill] {
  transition: fill 0.3s ease;
  fill: #ACC429 !important;
}
.style-whatsapp{
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 58%,transparent 0)
}
.style-linkedin{
  // padding: 0.4rem;
  // border-radius: 50%;  
  // color: #fff;
  background-color: #5C7ACB !important;
}
.style-facebook{
  background-color: #3B70C0 !important;
}
.style-twitter{
  padding: 0.4rem;
  border-radius: 50%;  
  color: #fff;
}
.style-intstagram{
  // padding: 0.3rem;
  // border-radius: 50%;  
  // color: #fff;
  background-color: #DD46A5 !important;
}
.style-link{
  // padding: 0.4rem;
  // border-radius: 50%;  
  // color: #fff;
  background-color: #D1B66A !important;
}
.sytle-label-title{
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}
@media only screen and (max-width: 850px) {
  .borderFirst{
    border: none;
  }
  .borderLast{
    border: none;
  }
  .borderBetween{
    border: none;
  }
}