.stacked-card {
    border-radius: 80px 80px 0px 0px;
    box-shadow: 0px -3px 20px #0000001A;
}
.stacked-card-padding{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.base-card {
    position: relative;
}

.icon-card {
    position: absolute;
    top: -75px;
    right: 300px;
}

@media only screen and (min-width:451px) and (max-width: 850px) {
    .icon-card{
        right: 200px;
    }
}
@media only screen and (max-width: 450px) {
    .icon-card{
        right: 100px;
    }
}