.sub-header{
    font-weight: bold;
    align-items: center;    
}
.input-border-custom{
    border: 1px dashed rgba($color: #000000, $alpha: 0.2);
}
.custom-label{
    border-radius: 0.25rem;
    background-color: #006CB83B;
    color: #0F6EB5;
    border-color: #0F6EB5;
}
.fileuploader-container{
    border: 1px solid #d3d3d3;
}
.headerCustom .dx-header-row{
    background-color: #E6E6E6;
    font-size: 36px;
    font-weight:bold;
}
.rowColorCustom .dx-row .dx-row-alt{
    background-color: red;
}
.customButtonAdd {
    background-color: #ACC239;
    width: 20px;
    height: 20px;
    padding-top: 2px;
}
.customSwitch .dx-switch-wrapper{
    position:unset;
    padding: 0;
}

.customButton {
    background-color: #44E08D;
    border-color: #44E08D;
}
.customAutoComplate.dx-texteditor.dx-editor-underlined::after {
    border-bottom: none !important;
}
.customAutoComplateHover.dx-texteditor.dx-editor-underlined.dx-state-hover::after {
    border-bottom: none !important;
}
.dx-state-focused::before {
    border-bottom: none !important;
}
.customBorder{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border: 1px solid #ebedf2;
}
.customtable .table th, .table td {
    border-top: none;
}

.dx-accordion-item-title {
    height: auto;
    border-top: 1px solid #ebedf2;
    padding: 10px;
    border-bottom	: 1px solid #ebedf2;
}
.dx-accordion-item-opened > .dx-accordion-item-title {
    height: auto;
    background-color: #f4f5f8;
}
.dx-accordion-item {
    /* background-color: #fff; */
    border-left: 1px solid #ebedf2;
    border-right: 1px solid #ebedf2;
    border-bottom: 1px solid #ebedf2;
    box-shadow: none;
}
.dx-accordion-item-body {
    padding: 0px; 
}
.dx-accordion-item.dx-state-active > .dx-accordion-item-title,
.dx-accordion-item.dx-state-focused > .dx-accordion-item-title {
  color: rgba(0,0,0,.87);
  background-color: #f4f5f8;
}
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
    background-color: #f4f5f8;
  }
  
.customTableBorderRowCol{
    border: 1px solid #006CB8;
  }
.customTableBorderHeader{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #006CB8;
    color: white;
  }
.ctsm-acordion .dx-accordion-item-title{
    border-top: none !important;
    border-bottom: none !important;
}
.ctstmText{
    font-size: 8pt !important;
    font-family: 'Poppins' !important;
}
.borderGridCstm >tr >td {
    padding: 2px;
    border: 1px solid #006CB8 !important;
}
.dangerous-text 
>p >span {
    font-size: 9pt !important;
}

.dangerous-text-1 >p >span{
    font-size: 9pt !important;
}

