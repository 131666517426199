.btn-floating {
    position: fixed;
    z-index: 999;
    width: 150px;
    height: 55px;
    border: none;
    background: #44E08D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 35px;
    opacity: 1;
    color: white;
    bottom: 30px;
    right: 50px;
}