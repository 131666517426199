.doc-export-style {
    font-size: 7pt;
    font-family: Arial, sans-serif;
    font-style: normal;
    color: black;
}

.doc-export-style .header-doc>div>span {
    font-size: 11pt;
    font-weight: 700;
    text-align: center;
    display: block;
}

.doc-export-style .row-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    padding: 5px;
}

.doc-export-style .row-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
    padding: 5px;
}


.doc-export-style .row-2>span {
    font-size: 6pt;
}

.doc-export-style .row-4 {
    display: flex;
    width: 100%;
    border-color: black;
    height: 130px;
}

.doc-export-style .row-4>div>div {
    padding: 12px 5px 5px 5px;
}

.doc-export-style .row-7 {
    display: flex;
    flex-direction: column;
    height: 130px;
    padding: 5px;
}

.doc-export-style .row-7 .content-left-row-7 {
    text-align: left;
    /* margin-left: 20px; */
}

.doc-export-style .row-7 .content-right-row-7 {
    text-align: left;
    /* margin-left: 60px; */
}

.toolbar-report-status {
    width: 100%;
    left: 0;
    background: #ffffff;
    position: fixed;
    top: 0px;
    min-height: 55px;
    box-shadow: 0px -8px 12px 11px #e0e0e0;
    padding: 10px;
    z-index: 99;
    zoom: 80%;
}