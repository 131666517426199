.left-style-ms {
    clip-path: polygon(0% 0%, 95% 0, 100% 50%, 95% 100%, 0% 100%);
    position: absolute;
    width: 100%;
    padding-top: 10px;
    /* left: 18px; */
    padding-bottom: 10px;
    background-color: #0F6EB6;
}

.right-style-ms {
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%, 0 0);
    position: absolute;
    /* right: 15px; */
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #0F6EB6;
}
.content-ms{
    margin-top: 55px;
    font-size: 11px;
    /* padding-left: 20px; */
}
@media (max-width: 768px){
    .left-style-ms {
      left: 0;
      width: 95%;
    }
    .right-style-ms{
        width: 95%;
    }
  }