.gridBorder-col .dx-datagrid .dx-column-lines>td {
    border-left: 1px solid #006CB8;
    border-right: 1px solid #006CB8;
}


.headerCustomTimeline .dx-header-row {
    background-color: #006CB8;
    font-size: 8pt;
    font-family: 'Poppins';
    color: white;
    text-align-last: center;
    font-weight: bold;
}

.gridBorder .dx-datagrid-borders {
    border: 1px solid #006CB8 !important;
}

.gridBorder-content .dx-datagrid-content>table>tbody>tr>td {
    border: 1px solid #006CB8 !important;
}

.gridBorder .dx-datagrid-content {
    border: 1px solid #006CB8 !important;
}

.textContent {
    font-size: 8pt;
    font-family: 'Poppins' !important;
}

.ctmsizeSpan {
    width: 32px;
    height: 32px;
}

.cstmbgColorActual {
    background-color: #DFDCF5 !important;
}

.cstmbgColorPlan {
    background-color: #f5f5fc !important;
}

.bgTaskPlan {
    background-color: #DFDCF5 !important;
}

.bgTaskActual {
    background-color: #f5f5fc !important;
}

.bgMlsPlan {
    background-color: #ffc252 !important;
}

.bgMlsActual {
    background-color: #fce4b8 !important;
}

.bgTaskActual .cstmbgColorEditorActualDisable {
    background-color: #f5f5fc !important;
    border-color: #f5f5fc !important;
}

.bgMlsActual .cstmbgColorEditorActualDisable {
    background-color: #fce4b8 !important;
    border-color: #fce4b8 !important;
}

.my-tooltip>.tooltip-inner {
    background-color: red;
    color: white;
}

.my-tooltip>.tooltip-arrow {
    background-color: yellow;
    color: blue;
}

.customPadding-col .dx-datagrid .dx-row>td {
    padding: 0;
}

.command-button-padding .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit.dx-command-edit-with-icons {
    padding: 7px;
}
.grid-timeline .dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td {  
    background-color: transparent;  
} 
