@import "../../themes/generated/variables.base.scss";
.col-custom{
    margin: 10px;
}
.col-header{
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    font-weight: bold;
    align-items: center;
}
.cstm-brdr{
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
}
.text-content{
    font-size: 8pt;
}
.text-title{
    font-weight: bold;
    font-size: 8pt;
}
.col-content{
    border-bottom: 0;
    font-size: 10px;
    padding-top: 15px;
    }
.header-body{
    width: 100%;
    background-color: #EAF0FF;
    border-radius: 7px;
    margin: 10px;
}
.header-font{
    font: normal normal bold 22px/33px Proppins;
}
.version-style{
    background-color: #716ACA;
    color: white;
    margin-left: 10px;
    font-size: 9px;
    align-self: center;
    border-radius: 25px;
}
.status-custom {
    color: white;
    padding: 5px;
    /* position: absolute; */
    z-index: 99;
    border-radius: 27px;
    width: 100%;
    text-align: center;
}
