/* body {
    zoom: 80%;
} */

.loginPage {
    height: 100%;
    width: 100%;
    background: url(./bgtop.svg) -477.5099792480469px -389.6597900390625px no-repeat,url(./bgbottom.svg) 1260px 529px no-repeat, url(./bgcenter.svg) 645px 135px no-repeat;
    background-size: 1040px, 713px, 714px;
    overflow:hidden;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.3);
}

    .footer a {
        color: rgba(0, 0, 0, 0.3);
    }

.bgtop {
    background: url('./bgtop.svg') no-repeat;
}

.bgbottom {
    background: url('./bgbottom.svg') no-repeat;
}

.bgcenter {
    background: url('./bgcenter.svg') no-repeat;
}

.card-login {
    border-radius: 15px;

}


@media only screen and (min-width: 1400px) {
    .card-login {
        /* position: absolute; */
        /* left: 116px; */
        /* top: 116px; */
        margin-left: 12%;
        margin-top: 12%;
        width: 350px;
        box-shadow: 10px 4px 14px -5px rgba(0,0,0,0.3);
        z-index:99;
    }

    .orgmerah {
        position: relative;
        left: -62px;
        top: 252px;
        width: 175px;
        z-index: 12;
        animation-name: orgmerah;
        animation-duration: 1s;
    }

    .kursikiri {
        position: relative;    
        bottom: -290px;
        left: -255px;
        z-index: 11;
        height: 220px;
        animation-name: kurikiri;
        animation-duration: 1s;
    }

    .orgbiru {
        position: relative;
        left: -177px;
        top: 230px;
        height: 335px;
        z-index: 11;
        animation-name: orgbiru;
        animation-duration: 1s;
    }

    .mejaset {
        position: relative;
        left: 26px;
        top: 0px;
        height: 220px;
        z-index: 20;
        animation-name: mejaset;
        animation-duration: 1s;
    }

    .kursikanan {
        position: relative;
        z-index: 5;
        left: -20px;
        top: 10px;
        height: 200px;
        animation-name: kursikanan;
        animation-duration: 1s;
    }

    .screen {
        position: relative;
        top: -467px;
        z-index: 5;
        width: 220px;
        left: 100px;
        animation-name: screen;
        animation-duration: 1s;
    }

    .jendela {
        position: relative;
        left: -470px;
        top: -260px;
        width: 150px;
        animation-name: jendela1;
        animation-duration: 1s;
    }

    .jam {
        position: relative;
        top: -490px;
        left: 100px;
        z-index: 5;
        height: 100px;
        animation-name: jam;
        animation-duration: 1s;
    }

    .orghijau {
        position: relative;
        left: -185px;
        top: 260px;
        height: 270px;
        z-index: 11;
        animation-name: orghijau;
        animation-duration: 1s;
    }

    .logopertamina {
        position: absolute;
        width: 220px;
        right: 75px;
        top: 35px;
    }

    .logospiirit {
        width: 150px;
        margin: 20px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1400px) {    
    .card-login {
        /* position: absolute; */
        /* left: 116px; */
        /* top: 116px; */
        margin-left: 12%;
        margin-top: 10%;
        width: 350px;
        box-shadow: 10px 4px 14px -5px rgba(0,0,0,0.3);
        z-index:99;
    }

    .orgmerah {
        position: relative;
        left: -58px;
        top: 243px;
        width: 155px;
        z-index: 12;
        animation-name: orgmerah;
        animation-duration: 1s;
    }

    .kursikiri {
        position: relative;
        bottom: -275px;
        left: -228px;
        z-index: 11;
        height: 210px;
        animation-name: kurikiri;
        animation-duration: 1s;
    }

    .orgbiru {
        position: relative;
        left: -170px;
        top: 210px;
        height: 320px;
        z-index: 11;
        animation-name: orgbiru;
        animation-duration: 1s;
    }

    .mejaset {
        position: relative;
        left: 26px;
        top: 0px;
        height: 200px;
        z-index: 20;
        animation-name: mejaset;
        animation-duration: 1s;
    }

    .kursikanan {
        position: relative;
        z-index: 5;
        left: -15px;
        top: 10px;
        height: 180px;
        animation-name: kursikanan;
        animation-duration: 1s;
    }

    .screen {
        position: relative;
        top: -424px;
        z-index: 5;
        width: 200px;
        left: 97px;
        animation-name: screen;
        animation-duration: 1s;
    }

    .jendela {
        position: relative;
        left: -425px;
        top: -234px;
        width: 140px;
        animation-name: jendela1;
        animation-duration: 1s;
    }

    .jam {
        position: relative;
        top: -466px;
        left: 90px;
        z-index: 5;
        height: 100px;
        animation-name: jam;
        animation-duration: 1s;
    }

    .orghijau {
        position: relative;
        left: -175px;
        top: 245px;
        height: 250px;
        z-index: 11;
        animation-name: orghijau;
        animation-duration: 1s;
    }

    .logopertamina {
        position: absolute;
        width: 200px;
        right: 75px;
        top: 35px;
    }

    .logospiirit {
        width: 110px;
        margin: 20px;
    }
}
@media only screen and (max-width: 1000px) {

    .orgmerah {
        position: relative;
        left: -28px;
        top: 328px;
        width: 152px;
        z-index: 12;
    }

    .kursikiri {
        position: relative;
        bottom: -361px;
        left: -211px;
        z-index: 11;
        height: 211px;
    }

    .orgbiru {
        position: relative;
        left: -177px;
        top: 307px;
        height: 328px;
        z-index: 11;
    }

    .mejaset {
        position: relative;
        left: 56px;
        top: 95px;
        height: 206px;
        z-index: 20;
    }

    .kursikanan {
        position: relative;
        z-index: 5;
        left: 0px;
        top: 95px;
        height: 189px;
    }

    .screen {
        position: relative;
        top: -371px;
        z-index: 5;
        width: 205px;
        left: 210px;
    }

    .jendela {
        position: relative;
        left: -330px;
        top: -200px;
        width: 120px
    }

    .jam {
        position: relative;
        top: -436px;
        left: 200px;
        z-index: 5;
        height: 78px;
    }

    .orghijau {
        position: relative;
        left: -163px;
        top: 307px;
        height: 275px;
        z-index: 11;
    }

    .logopertamina {
        position: absolute;
        right: 75px;
        top: 35px;
    }

    .logospiirit {
        width: 150px;
        margin: 20px;
    }
    .card-login {
        /* position: absolute; */
        /* left: 116px; */
        /* top: 116px; */
        margin-left: 12%;
        margin-top: 12%;
        width: 75%;
        box-shadow: 10px 4px 14px -5px rgba(0,0,0,0.3);
        z-index: 99;
    }
}
@media only screen and (max-width: 850px) {
    .loginPage{
        overflow: hidden;
    }
    .card-login {
        /* position: absolute; */
        /* left: 116px; */
        /* top: 116px; */
        margin-left: 12%;
        margin-top: 12%;
        width: 75%;
        box-shadow: 10px 4px 14px -5px rgba(0,0,0,0.3);
        z-index:99;
    }

    .none-on-devices {
        display: none;
    }
}

.form-login {
    height: 40px;
    margin: auto;
    border-radius: 8px;
    /* background: var(--unnamed-color-f0f0f0) 0% 0% no-repeat padding-box; */
    background: #F0F0F0 0% 0% no-repeat padding-box;
    /* border-radius: 10px; */
    opacity: 1;
    width: 290px;
}

@keyframes jendela1{
    from {
        top: -2440px;
    }
    to {
        top: -244px;
    }
}

@keyframes jam {
    from {
        top: -2440px;
    }
    to {
        top: -536px;
    }
}

@keyframes screen {
    from {
        top: -4810px;
    }
    to {
        top: -481px;
    }
}

@keyframes mejaset {
    from {
        top: 450px;
    }
    to {
        top: 45px;
    }
}

@keyframes kursikanan {
    from {
        top: 400px;
    }
    to {
        top: 57px;
    }
}

@keyframes kurikiri {
    from {
        bottom: -671px;
    }
    to {
        bottom: -371px;
    }
}

@keyframes orgmerah {
    from {
        left: -378px;
    }
    to {
        left: -78px;
    }
}

@keyframes orgbiru {
    from{
        left:-500px
    }
    to {
        left: -227px;
    }
}

@keyframes orghijau{
    from {
        left: 400px
    }

    to {
        left: -233px;
    }
}