.nav-color {
    background-color: white;
}

.nav-color-transparent {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px -3px 20px #0000001A;
}

.pointer-style {
    cursor: pointer;
}

.btn-masuk {
    background-color: #006CBB !important;
    border-color: #006CB8 !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    color: white !important;
    transition:  background-color 300ms ease-out, border-color 300ms ease-out;
}
.btn-masuk:hover {
    background-color: #3699FF !important;
    border-color: #3699FF !important;
}