.custom-style > 
.dx-datagrid > 
.dx-datagrid-headers > 
.dx-datagrid-content >
table > tbody > tr > td{
    background: #006CB8;
    color: white;
    /* text-align: left !important; */
    border: 1pt #006CB8 solid;
}

.custom-style .dx-datagrid .dx-header-filter{
    color: white;
}
.custom-style .dx-datagrid-content >
table > tbody > tr:nth-child(even) {
    background: #D4E6F2;
    color: #808286;
 }
 .detail-footer{
     left: -1px;
     width:100%
 }
 .header_product_display{
    /* height: 119px; */
    background-image: url('/public/svg/productimg.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
  .custom-footer-grid {
    background-color: #006CB8;
    color: white;
}
.background_1{
    background-image: url('/public/svg/highligh-dashboard.svg') !important;
    background-size: contain !important;
    background-position-y: bottom !important;
    background-repeat: no-repeat;
    background-color: #F2BA22 !important;
}
.background_2{
    mask-image: url('/public/svg/highligh-dashboard.svg') !important;
    position: absolute;
    mask-size: contain !important;
    mask-position: bottom !important;
    mask-repeat: no-repeat;
    background-color: grey !important;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.background_3{
    background-image: url('/public/svg/highligh-dashboard.svg') !important;
    background-size: contain !important;
    background-position-y: bottom !important;
    background-repeat: no-repeat;
    background-color: #326BD5 !important;
}
.background_4{
    background-image: url('/public/svg/highligh-dashboard.svg') !important;
    background-size: contain !important;
    background-position-y: bottom !important;
    background-repeat: no-repeat;
    background-color: #F2BA22 !important;
}

.box-header-product{
    font-size: 12px;
}