.custmpadding .dx-datagrid-content >
table > tbody > tr > td {
    padding: 0;
 }

 .cstmBrdrDrawdown > 
.dx-datagrid  {
    border: none !important;
}
.cstmBrdrDrawdown 
.dx-datagrid .dx-column-lines > td{
    border: none !important;
}