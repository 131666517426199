.card_1{
  background-image: url('/public/svg/highligh-dashboard.svg') ;
  background-size: contain !important;
  background-position-y: bottom !important;
  background-repeat: no-repeat;
}
.card_2{
  mask-image: url('/public/svg/highligh-dashboard.svg') ;
  position: absolute;
  mask-size: contain !important;
  mask-position: bottom !important;
  mask-repeat: no-repeat;
  background-color: grey !important;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 12.5px;
  padding-right: 12.5px;
}
.header_research_display{
  /* height: 280px; */
  background-image: url('/public/svg/researchimg.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}
.header_library_display{
  /* height: 290px; */
  background-image: url('/public/svg/libraryimg.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}
.image_card {
  background-color: white;
  border-radius: 3%;
  object-fit: contain;
}
.textTitleKm{
  color: black !important;
}
.textTitleKm:hover{
  color: #337ab7 !important;
}