.carousel-status{
    display: none;
}
.carousel-root > .carousel > .slider-wrapper > .slider > .slide{
    background: none !important;
}

.btn-carousel{
    background: white;
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    /* padding: 10px; */
    z-index: 20;
    top: 40%;
    /* border-radius: 40px; */
    border: 1pt solid #ececec;
    box-shadow: 10px -1px 14px -2px rgb(0 0 0 / 10%);
}

.btn-carousel:hover{
    cursor: pointer;
}

.btn-left{
    left: -8px;
}
.btn-right{
    right: 0px;
}
  