.customNavLink  {
    border-radius: 0 !important;
}
.customNavLink .active{
    background-color: black !important;
}
.nav.nav-pills .show > .nav-link, .nav.nav-pills .nav-link.active {
    color: black !important;
    border-left: 4px solid #369AFF;
    background-color: rgba(198, 198, 198, 0.2) !important;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !important;
}
.cardMargin{
    margin-left: 30px !important;
    margin-right: 30px !important;
}